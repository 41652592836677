import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/license";

export default {
  getTerms(user) {
    var config = {};
    if (user !== undefined || user !== null) {
      config["headers"] = getAuthHeader(user);
    }

    return Repository.get(`${resource}/terms`, config);
  },
};
